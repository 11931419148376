<template>

    <div v-if="isActive">
        <Alert alert="warning" :message="__('The application is currently on maintenance. Only the administrator can access the application.')"></Alert>
    </div>

</template>

<script setup>
import Alert from '@/Components/Alert.vue';
import { usePage } from '@inertiajs/vue3';

let isActive = usePage().props.maintenance_active;

</script>
